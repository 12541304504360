import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { toast } from "react-hot-toast";
import { tableData } from "../pages/Sap/config/table";
import { SAP_TABLE } from "../pages/Sap/graphql/sap-table.query";
import { SAP_VIEW } from "../pages/Sap/graphql/sap-view.query";
import moment from "moment";
import {getErrorMessage} from "../utils/error.message";

const SERVICE_NAME = "sap.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export class SapService {
    name = SERVICE_NAME;
    private static instance: SapService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new SapService();
        }
        return this.instance;
    }

    async getSapTable(limit: number, skip: number, filter: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        log.info("filter :", filter)
        let _date = filter?.date ?? [];
        let  startDate= _date && Array.isArray(_date) && _date.length > 1 ?  _date[0] : null;
        let endDate = _date && Array.isArray(_date) && _date.length > 1 ? _date[1] : null;
        log.info("startDate filter:", startDate, "endDate :", endDate);
        startDate = startDate && moment(startDate).utc().startOf('day')
        startDate = startDate && moment(startDate).add('days', 1)
        startDate = startDate && moment(startDate).subtract(330, 'minute')
        startDate = startDate && moment(startDate).utc().format()
        // startDate =
        //     startDate &&
        //     moment(startDate).utc().startOf("day").add("days", 1).format();
        endDate = endDate && moment(endDate).utc().endOf('day')
        endDate = endDate && moment(endDate).add('days', 1)
        endDate = endDate && moment(endDate).subtract(330, 'minute')
        endDate = endDate && moment(endDate).utc().format()
        // endDate =
        //     endDate &&
        //     moment(endDate).utc().endOf("day").add("days", 1).format();
        log.info("startDate filter effect:", startDate, "endDate :", endDate);
        try {
            if (client) {
                const res = await client.query({
                    query: SAP_TABLE,
                    variables: {
                        skip: skip,
                        limit: limit,
                        criteria: {
                            unit: filter?.unit ?? null,
                            product: filter?.product ?? null,
                            gtin: filter?.gtin ?? null,
                            batch: filter?.batch ?? null,
                            fileName: filter?.fileName ?? null,
                            status: filter?.status ?? null,
                            startDate: filter?.date && Array.isArray(filter?.date) && filter?.date[0] !== "" ? startDate : null,
                            endDate: filter?.date && Array.isArray(filter?.date) && filter?.date[1] !== "" ? endDate : null,
                            download: filter?.download ?? false
                        },
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.sapTable) return res?.data?.sapTable;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    // _getSapView = (id: string, data: any) => {
    //     let res ;
    //     data && Array.isArray(data) && data.length > 0 && data.forEach((item: any, index: number) => {
    //         if(item?._id === id){
    //             res = item;
    //         }
    //     });
    //     return res;
    // }

    async getSapView(payload: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: SAP_VIEW,
                    variables: {
                        ...payload,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getTNTDump) return res?.data?.getTNTDump;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
